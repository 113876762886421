import { StyleSheet, Platform, Dimensions } from "react-native";
import scale, { verticalScale } from "../../../framework/src/utils/Scale";
import { FONTS } from "../../studio-store-ecommerce-theme/src/AppFonts";
const themeJson = require("../../studio-store-ecommerce-theme/src/theme.json");
import COLOR_CONST from "../../studio-store-ecommerce-theme/src/AppFonts";
const width = Dimensions.get('window').width
const height = Dimensions.get('window').height

export default StyleSheet.create({
  container: {
    flex:1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: scale(40)
  },
  goBackButton: {
    height: 44,
    marginTop: 29,
    paddingHorizontal: 100,
    marginBottom: 26,
    backgroundColor: '#fff',
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 22,
    borderWidth: 1,
    borderColor: '#00a5b8',
    bottom: 0, alignSelf: 'center'
  },
  goBackText: {
    color: '#00a5b8',
    fontSize: 14,
    opacity: 1,
    fontWeight: '800'
  },
  imgProfile: {
    height: (150), width: (150)
  },
  headerText: {
    marginTop: (20),
    marginBottom: (10),
    color: themeJson.attributes.header_text_color,
    fontSize: 36,
    textAlign: 'center',
  },
  subText: {
    color: themeJson.attributes.header_text_color,
    fontSize: 20,
    textAlign: 'center',
  }
  // Customizable Area End
});

