import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import axios from "axios";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// @ts-ignore
import map from "lodash/map";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  wishlist: any;
  order: any;
  history: any;
  getOrders: any;
  // cancelOrder: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  ShowCOModal: boolean;
  ShowPRModal: boolean;
  currentOrder: any;
  activeTab: string;
  orders: any;
  cancelOrder: any;
  reviewProduct: any;
  // Customizable Area Start
  userDetails?: any;
  values: any;
  messageSent: boolean;
  sending: boolean;
  ShowContactModal: boolean;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SingleOrdersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrdersCallId: string = "";
  cancelOrderCallId: string = "";
  writeReviewCallID: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  getAllNotificationsAPICallId: string = "";
  addContactApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.confirmCancelOrder = this.confirmCancelOrder.bind(this);
    this.openProductRatingModal = this.openProductRatingModal.bind(this);
    this.writeReview = this.writeReview.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      ShowCOModal: false,
      ShowPRModal: false,
      currentOrder: {},
      activeTab: "1",
      orders: [],
      cancelOrder: {},
      reviewProduct: {},
      // Customizable Area 
      ShowContactModal: false,
      values: undefined,
      messageSent: false,
      sending: false,
      loading:false,
      //@ts-ignore
      userDetails: JSON.parse(localStorage.getItem("user")),
      // Customizable Area End
    };
    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // this.getOrders();
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorMessage = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (this.parseExpireTokenResponse(responseJSON, this.state, this.props)) {
        if (this.getOrdersCallId === apiRequestCallId) {
          this.setState({ orders: responseJSON?.data?.order });
        }

      if (this.cancelOrderCallId === apiRequestCallId) {

        if (responseJSON?.errors) {
          map(responseJSON.errors, (error: any) => {
            map(error, (value: any, key: any) => {
              // @ts-ignore
              window.notify([{ message: value || "Something went wrong!!!", type: "danger" }]);
            })
          })
        } else {
          // @ts-ignore
          window.notify([{ message: responseJSON?.data?.message || "Order cancelled successfully!!!", type: "success" }]);
          this.props.getOrders();
        }
      }

      if (this.writeReviewCallID === apiRequestCallId) {
        if (responseJSON?.errors) {
          map(responseJSON.errors, (error: any) => {
            map(error, (value: any, key: any) => {
              // @ts-ignore
              window.notify([{ message: value || "Something went wrong!!!", type: "danger" }])
            })
          })
        } else if (responseJSON?.data) {
          // @ts-ignore
          window.notify([{ message: "Thank you for your review!!!", type: "success" }])
          this.props.getOrders();
          this.openProductRatingModal();
        }
      }

      if (this.addContactApiCallId === apiRequestCallId) {
        this.setState({ loading: false });
        
        if (responseJSON?.success) {
          this.setState({
            messageSent: true,
            ShowContactModal: false,
            ShowCOModal: false,
            cancelOrder: {},
          });
          // @ts-ignore
          window.notify([
            { type: "success", message: "Thank you for contact us !!!" },
          ]);
        }
        else if (responseJSON?.errors) {
          map(responseJSON.errors, (error: any) => {
            map(error, (value: any, key: any) => {
              // @ts-ignore
              window.notify([{ message: value || "Something went wrong!!!", type: "danger" }])
            })
          })
        }
        else {
          this.parseApiErrorResponse(responseJSON);
        }
      }

      if (apiRequestCallId === this.getAllNotificationsAPICallId) {
        if (responseJSON && responseJSON.data) {
          const { notifications, unreadable_count } = responseJSON.data;
          const { data, meta } = responseJSON;
          let unreadNotifyCount: number = 0;
          unreadNotifyCount = unreadable_count;
          const countNotify = localStorage.getItem('notifctaion_len');
          //@ts-ignore
          if (countNotify != unreadNotifyCount) {
            //@ts-ignore
            localStorage.setItem('notifctaion_len', unreadNotifyCount);
          }
        }
      }
    }

      
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  getOrders() {
    const token = localStorage.getItem("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getOrdersCallId = requestMessage.messageId;
    // Set Method Type
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetMethodType
    );
    // Set EndPoints
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyOrdersListEndPoint
    );
    // set Headers
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    // Send Request

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  routeToProfile(value: string) {
    let matchTabName = "";
    switch (value) {
      case "profile":
        matchTabName = "1";
        break;
      case "wishlist":
        matchTabName = "2";
        break;
      case "myorder":
        matchTabName = "3";
        break;
      case "saveaddresses":
        matchTabName = "4";
        break;
      case "connectaccount":
        matchTabName = "5";
        break;
      default:
        matchTabName = "1";
        break;
    }
    // @ts-ignore
    this.setState({
      activeTab: matchTabName,
    });
  }

  openProductRatingModal() {
    // pRsettt(!ShowPRModal);
    this.setState((prevState) => ({
      ShowPRModal: !prevState.ShowPRModal,
    }));
  }

  setProductAndOpenPM(product: any) {
    this.openProductRatingModal();
    this.setState({ reviewProduct: product });
  }

  openCancelOrderModal(order: any, item: any) {
    this.setState({
      cancelOrder: { order, item },
      ShowCOModal: true,
    });
  }

  confirmCancelOrder() {
    this.setState({
      ShowCOModal: !this.state.ShowCOModal,
      ShowContactModal: true
    });
    //this.props.history.push('/contact-us');
    return;
    const { cancelOrder: { order, item } } = this.state;
    const token = localStorage.getItem("token");
    this.setState({
      currentOrder: { orderId: order?.id, itemId: item?.id },
      ShowCOModal: !this.state.ShowCOModal,
    });
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cancelOrderCallId = requestMessage.messageId;
    // Set Method Type
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutMethodType
    );
    // Set EndPoints
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `order_management/orders/${order.id}/cancel_order`
    );
    // set Headers
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    // Send Request

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  toggleCancelModal = () => {
    this.setState({ ShowCOModal: !this.state.ShowCOModal });
  };

  routeToOrderDetails(order: any, item: any) {
    this.props.history.push({
      pathname: `profile/myorder/${order.id}/${item.id}`,
      state: { order, orderItem: item },
    });
  }

  writeReview(data: any) {
    const { comment, rating } = data;
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": "application/json",
      token,
    };
    const body = {
      comment,
      rating,
      order_item_id: this.state?.reviewProduct?.id,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.writeReviewCallID = requestMessage.messageId;
    // Set Method Type
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostMethodType
    );
    // Set EndPoints
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitOrderReviewEndPoint
    );
    // set Headers
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    // set Body
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    // requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(requestBody));

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  //Get All Notifications API
  getAllNotificationsList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllNotificationsAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllNotificationsAPIEndPoint
    );
    const headers = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GetMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area Start

  toggleContactModal = () => {
    this.toggleCancelModal();
    this.setState({ ShowContactModal: !this.state.ShowContactModal })
  }

   //submitting contact us form
   submitContactusForm(values: any) {
    this.setState({ loading: true });
    const token = localStorage.getItem("token");
    const GuestUserData = localStorage.getItem("guestUUID");
    let requestBody;

    //@ts-ignore
    const userData = JSON.parse(localStorage.getItem("user"));
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addContactApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveContactUsApiEndPoint
    );

    const headers = {
      "Content-Type": "application/json",
      token,
    };
    if (GuestUserData) {
      requestBody = {
        data: {
          attributes: {
            name: values.name,
            phone_number: values.phone,
            email: values.email,
            description: values.message,
            purpose_of_contact: "To cancel order for order id #" + this.state.cancelOrder.order.id
          },
        },
      };
    } else {
      requestBody = {
        data: {
          attributes: {
            name: values.name,
            phone_number: values.phone,
            email: values.email,
            description: values.message,
            account_id: userData.id,
            purpose_of_contact: "To cancel order for order id #" + this.state.cancelOrder.order.id
          },
        },
      };
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  setCurrentImage = (data: any) => {
    let currentImage: any;
    if (
      data.attributes?.catalogue_variant?.attributes?.images?.data
    ) {
      if (
        data.attributes?.catalogue_variant?.attributes?.images?.data.length > 0
      ) {
        data.attributes?.catalogue_variant?.attributes?.images?.data?.map(
          (ele: any, index: number) => {
            if (ele?.attributes?.is_default) {
              currentImage = ele?.attributes?.url;
            }
          }
        );
      } else {
        currentImage =
          data.attributes?.catalogue_variant?.attributes?.images?.data?.attributes?.url;
      }
    } else {
      if (
        data?.attributes?.catalogue?.attributes?.images?.data &&
        data?.attributes?.catalogue?.attributes?.images?.data.length > 0
      ) {
        data?.attributes?.catalogue?.attributes?.images?.data.map(
          (ele: any, index: number) => {
            if (ele?.attributes?.is_default) {
              currentImage = ele?.attributes?.url;
            }
          }
        );
      } else {
        currentImage = data?.attributes?.catalogue?.attributes?.images?.data.length > 0 ?
          data?.attributes?.catalogue?.attributes?.images?.data[0]?.attributes?.url :
          data?.attributes?.product_images?.data?.attributes?.url;
      }
    }
    return currentImage;
  };
  // Customizable Area End
}
