import React from "react";
import { Link } from 'react-router-dom';
import {
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  Platform
} from "react-native";

import UnderReviewController, { Props } from "./UnderReviewController.web";
import FocusAwareStatusBar from "../../../components/src/FocusAwareStatusBar";
import styles from "./UnderReviewStyle";
import { EmailIcon, pwdIcon, phoneIcon, userIcon, numberImg, logoImg } from './assets';
export default class UnderReview extends UnderReviewController {

  render() {
    return (
      // Customizable Area Start
      <View
        style={{ backgroundColor: '#00a5b8', flex: 1, position: 'absolute', zIndex: 11, width: '100%', height: '100%', padding: 100, top: 0 }}
      >
        <View style={styles.container}>
          <FocusAwareStatusBar
            barStyle="light-content"
            backgroundColor={'#00a5b8'}
            isFocused={true}
          />
          <Image
            {...this.imgProfileIcon}
            style={styles.imgProfile}
          />

          <Text style={styles.headerText}>{this.state.headerText}</Text>

          <Text style={styles.subText}>{this.state.subText}</Text>

         

        <TouchableOpacity
          style={styles.goBackButton}
          onPress = {() => { 
            // @ts-ignore
            this.props?.history?.push('/');
           }} 
        >
          <Text style={styles.goBackText}>GO BACK</Text>
        </TouchableOpacity>

         
        </View>
        
      </View>
      // Customizable Area End
    );
  }
}
