import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import R from "../../../components/src/R";
export interface Props {
  navigation: any;
  fromCart: boolean;
}

interface S {
  headerText: string;
  subText: string;
}

interface SS {}

export default class UnderReviewController extends BlockComponent<Props, S, SS> {

  constructor(props: Props) {
    super(props);

    this.state = {
      headerText: 'Your profile is under review',
      subText: 'Please check after some time.'
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  async componentWillUnmount() {
    super.componentWillUnmount();
  }

  onPressGoBack = ()=> {
    this.props.navigation.replace("Auth");
  }
  
  imgProfileIcon = {
    source: R.underReview.imgProfile
  };
}
